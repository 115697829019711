import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PageLayout from "../components/PageLayout";
import { Container } from "react-bootstrap";
import { constants } from "../redux/constants";
import axios from "axios";

// Redux
import { RootState } from "../redux/reducers/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { saveRouteDetails } from "../redux/actions/routeActions";
import PlanSwitchRadio from "../components/PlanSwitchRadio";
import * as plansStyles from "./css/plans.module.scss";

import { Trans } from "react-i18next";
import { Script, graphql } from "gatsby";
import { resetShopState, updateShopObject } from "../redux/actions/shopActions";
import { updatePlanObject } from "../redux/actions/planActions";
import { ApiRequest } from "../models/proxy-api-request";
import { api } from "../utilities/api";
import { proxyApi } from "../utilities/proxy-api";
import { mapBoxOnePlans, getPartnerIdFromPageLocation, goTo } from "../utilities/utils";
import LearnMoreSection from "../components/LearnMoreSection";
import { mapPortaOnePlans } from "../utilities/portaone-utils";
import NewPlanSelectList from "../components/NewPlanSelectList";
import Modal from "../components/Modal";
import { useI18next } from "gatsby-plugin-react-i18next";
import PortableModal from "../components/PortableModal";
import { MaintenanceStatusType } from "../types/MaintenanceStatusType";
import { Plan } from "../types/Plan";
import { DataRow } from "../interfaces/DataRow";

const Plans = ({ location }) => {
  const dispatch = useDispatch();
  const dispatchShop = (obj) => dispatch(updateShopObject(obj) as any);
  const dispatchPlans = (obj) => dispatch(updatePlanObject(obj) as any);
  const shopDetails = useSelector((state: RootState) => state.shop);
  const planDetails = useSelector((state: RootState) => state.plans);
  const [gtagPlanList, setGtagPlanList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPlanType, setSelectedPlanType] = useState(shopDetails.country === constants.CANADA ? constants.POSTPAID_PLAN : constants.PREPAID_PLAN);
  const [country, setCountry] = useState(shopDetails.country ? shopDetails.country : constants.CANADA);
  const isCanada = shopDetails.country === constants.CANADA;
  const [initialPlanType, setInitialPlanType] = useState(constants.POSTPAID_PLAN);
  const { language } = useI18next();
  const applicationDown = shopDetails.maintenance_status === MaintenanceStatusType.ApplicationDown && country === constants.CANADA;

  // due to development constraints, these languages are only partially supported
  const specialLanguages = ["jp", "zh", "vi", "pt", "ko"];

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 576) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  const fromUSLinkHandle = () => {
    // Find plans from USA
    let plans = new URLSearchParams(window.location.search).get("plans");

    if (plans && plans.toLowerCase() === 'us') {
      setCountry(constants.USA);
      setSelectedPlanType(constants.PREPAID_PLAN);
    } else {
      return;
    }
  }
  

  const prepaidLinkHandle = () => {
    let type = new URLSearchParams(window.location.search).get("type");

    if (type && type.toLowerCase() === 'prepaid') {
      setInitialPlanType(constants.PREPAID_PLAN);
    }
  }

  const onCloseModal = () => {
    goTo('/', {}, true);
  }

  const onSIMClick = (plan: Plan) => {
      dispatchShop({ planId: plan.planId });
      dispatchShop({ data: plan.data });
      dispatchShop({ plan_fee: plan.plan_fee });
      dispatchShop({ pfCode: plan.pfCode });
      dispatchShop({ country: country });
      dispatchShop({ currency: isCanada ? constants.CANADA_CURRENCY : constants.USA_CURRENCY });
      dispatchShop({ planCarrier: plan.carrier });
      dispatchShop({ shipping_country: country });
      dispatchShop({ planTypeD: plan.planTypeD });
      dispatchShop({ isPostpaid: selectedPlanType.toLowerCase() === constants.POSTPAID_PLAN.toLowerCase() });
      dispatchShop({ editMode: false });
    };
  
    const onRegularSIMClick = (plan: Plan) => {
      onSIMClick(plan);
      dispatchShop({ esim: false });
      dispatchShop({ delivery_needed: true });
      if (plan.plintronPlanId) {
        dispatchShop({ us_plintron_plan_id: plan.plintronPlanId });
      }
      if (plan.subscriptionId) {
        dispatchShop({ us_plan_i_subscription: plan.subscriptionId });
      }
      goTo("/plan-info");
    };

  // referral link must take priority over partner IDs
  const verifyURLParamsAndGetCorrespondingPlans = (partnerIds) => {

    // URL params
    const params = new URLSearchParams(window.location.search);

    // retrieve referral
    let referral = params.get("referral");

    // retrieve plan ID
    let planid = params.get("planid");

    // handle referral logic
    if (referral) {
      api.verifyReferral(referral)
        .then(res => {
          let referrerInfo = JSON.parse(res.data);
          if (Object.keys(referrerInfo).length === 0) {
            // alert("The referral link you are attempting to use is invalid. Please check your link again or receive one from your friend.")
            // getUSplans(partnerIds.usPartnerId);
            // getCanadaPlans(partnerIds.canadaPartnerId);
            dispatchShop({ referral_cnum: "" });
            setModalVisible(true);
          } else if (!referrerInfo.SuspensionDate && !referrerInfo.TerminationDate) {
            dispatchShop({ referral_cnum: referral });
            getUSplans(0);
            getCanadaPlans(0);
          }
        })
        .catch(err => console.error(err));
    } else {
      getUSplans(partnerIds.usPartnerId, planid);
      getCanadaPlans(partnerIds.canadaPartnerId, planid);
      dispatchShop({ referral_cnum: "" });
    }
  }

  const deepEqual = (x, y) => {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
      ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqual(x[key], y[key]))
    ) : (x === y);
  }

  const onSwitchCountry = (country) => {
    setCountry(country);
    // business requirement
    // if Canada plans, we will show montly plan by default
    // if USA plans, we show the prepaid one.
    if (country === constants.CANADA) {
      // if there are no postpaid plans (from promocode), set to prepaid.
      if (planDetails.Canada.Postpaid.length === 0) {
        // if there are only data-only plans, set to data-only.
        if (planDetails.Canada.Data.length > 0 && planDetails.Canada.Prepaid.length === 0) {
          setSelectedPlanType(constants.DATA_ONLY_PLAN);
          return;
        }
        setSelectedPlanType(constants.PREPAID_PLAN);
        return;
      }
      setSelectedPlanType(constants.POSTPAID_PLAN);
    } else {
      setSelectedPlanType(constants.PREPAID_PLAN);
    }
  };

  /**
   * Gets all US plans from Proxy API.
   */
  const getUSplans = (usPartnerId, planid = null) => {
    const planApiCalls = [
      // US - Proxy API - Portaone
      usPartnerId
        ? proxyApi.plan.getPartnerPlans(new ApiRequest({ i_rep: usPartnerId }))
        : proxyApi.plan.getDefaultPlans()
    ];

    Promise.all(planApiCalls).then((results) => {
      const usPrepaidResults = mapPortaOnePlans(results[0].data.product_list);
      if (deepEqual(planDetails.USA.Prepaid, usPrepaidResults)) {
        return;
      }
      dispatchPlans({ USA: { Prepaid: usPrepaidResults } });
      dispatchShop({ us_main_i_product: results[0].data.main_i_product });
      dispatchShop({ us_i_rep: usPartnerId !== 0 ? usPartnerId : null });
      console.log(`US Prepaid ${usPartnerId ? 'Partner' : 'Default'} Plans Loaded`, usPrepaidResults);
    });
  };

  /**
   * Gets all Canadian plans from BoxOne API.
   * @param canadaPartnerId 
   * @param usPartnerId 
   */
  const getCanadaPlans = (canadaPartnerId, planid = null) => {
    dispatchShop({ usPromocode: '' });
    // affiliate link
    if (canadaPartnerId) {
      api.getPartnerPromoCodeAll(canadaPartnerId).then(res => {
        if (res && res.data) {
          const prepaidPromoCode = res.data.CANPrepaid ? res.data.CANPrepaid : "";
          const postpaidPromoCode = res.data.CANPostpaid ? res.data.CANPostpaid : "";

          console.log("BN: ", canadaPartnerId)
          console.log("Prepaid PromoCode: ", prepaidPromoCode)
          console.log("PostPaid PromoCode: ", postpaidPromoCode)
          if (res.data.USAPrepaid && res.data.USAPrepaid === 'SimcardNoCharge') {
            dispatchShop({ usSimcardNoCharge: true });
            dispatchShop({ usPromocode: res.data.USAPrepaid });
          } else {
            dispatchShop({ usSimcardNoCharge: false });
            dispatchShop({ usPromocode: '' });
          }

          const planApiCalls = [];
          // if prepaid promo code found at query to get by prepaid promo code
          if (prepaidPromoCode) {
            planApiCalls.push(api.getPlans("", prepaidPromoCode, constants.CANADA_CURRENCY, constants.PREPAID_PLAN));
            dispatchShop({ prepaidPromocode: prepaidPromoCode });
          }

          // if postpaid promo code found add query to get by postpaid promo code
          if (postpaidPromoCode) {
            planApiCalls.push(api.getPlans("", postpaidPromoCode, constants.CANADA_CURRENCY, constants.POSTPAID_PLAN));
            dispatchShop({ promocode: postpaidPromoCode });
          }

          // if no promo codes found for either, get default plans
          if (!prepaidPromoCode && !postpaidPromoCode) {
            planApiCalls.push(api.getPlans("", "", constants.CANADA_CURRENCY, constants.PREPAID_PLAN));
            planApiCalls.push(api.getPlans("", "", constants.CANADA_CURRENCY, constants.POSTPAID_PLAN));
            dispatchShop({ promocode: '' });
            dispatchShop({ prepaidPromocode: '' });
          }

          // Canada - BoxOne
          Promise.all(planApiCalls).then((results) => {
            let canadaPrepaidResults = [];
            let canadaPostpaidResults = [];
            switch (planApiCalls.length) {
              // if length is 2 the order will always be prepaid then postpaid regardless of
              // whether its default plans or plans with promo codes
              case 2:
                canadaPrepaidResults = mapBoxOnePlans(results[0].data, constants.PREPAID_PLAN);
                canadaPostpaidResults = mapBoxOnePlans(results[1].data, constants.POSTPAID_PLAN);
                break;
              // if length is 1 and prepaid promo code is there then we're retrieving prepaid plans from first result set and setting postpaid to empty
              // if length is 1 and postpaid promo code is there then we're retrieving postpaid plans from first result set and setting prepaid to empty
              case 1:
                canadaPrepaidResults = prepaidPromoCode ? mapBoxOnePlans(results[0].data, constants.PREPAID_PLAN) : [];
                canadaPostpaidResults = postpaidPromoCode ? mapBoxOnePlans(results[0].data, constants.POSTPAID_PLAN) : [];
                break;
            }

            // if no postpaid plans, set to view prepaid instead
            if (canadaPostpaidResults.length === 0) {
              setSelectedPlanType(constants.PREPAID_PLAN);
            }

            // find data-only plans, if they exist
            let canadaDataResults = [];
            canadaPrepaidResults.forEach((result) => {
              if (result.calling === "N/A" && result.message === "N/A") {
                canadaDataResults.push(result);
              }
            });
            // remove data-only plans from prepaid list
            canadaPrepaidResults = canadaPrepaidResults.filter(result => { return !(result.calling === "N/A" && result.message === "N/A") });

            // set selected plans to data-only if needed
            if (canadaPrepaidResults.length === 0 && canadaDataResults.length > 0) {
              setSelectedPlanType(constants.DATA_ONLY_PLAN);
            }
            dispatchPlans({ Canada: { Prepaid: canadaPrepaidResults, Postpaid: canadaPostpaidResults, Data: canadaDataResults } });
            console.log(`Canada Prepaid ${!postpaidPromoCode && !prepaidPromoCode ? 'Default' : 'Partner'} Plans`, canadaPrepaidResults);
            console.log(`Canada Postpaid ${!postpaidPromoCode && !prepaidPromoCode ? 'Default' : 'Partner'} Plans`, canadaPostpaidResults);
            console.log(`Canada Data ${!postpaidPromoCode && !prepaidPromoCode ? 'Default' : 'Partner'} Plans`, canadaDataResults);
            
            if (planid) {
              let foundPlan = findPlanByPlanId(planid, [ ...canadaPostpaidResults, ...canadaPrepaidResults ]);
              onRegularSIMClick(foundPlan as Plan)
            }

          });
        }
      }).catch((error) => {
        console.error(error);
      });
      return;
    }

    // default plans
    const planApiCalls = [
      // Canada - BoxOne
      api.getPlans("", "", constants.CANADA_CURRENCY, constants.PREPAID_PLAN),
      api.getPlans("", "", constants.CANADA_CURRENCY, constants.POSTPAID_PLAN),
    ];

    Promise.all(planApiCalls).then((results) => {
      const canadaPrepaidResults = mapBoxOnePlans(results[0].data, constants.PREPAID_PLAN);
      const canadaPostpaidResults = mapBoxOnePlans(results[1].data, constants.POSTPAID_PLAN);

      if (planid) {
        let foundPlan = findPlanByPlanId(planid, [ ...canadaPostpaidResults, ...canadaPrepaidResults ]);
        onRegularSIMClick(foundPlan as Plan)
      }

      if (deepEqual(planDetails.Canada.Prepaid, canadaPrepaidResults) && deepEqual(planDetails.Canada.Postpaid, canadaPostpaidResults)) {
        return;
      }
      dispatchPlans({ Canada: { Prepaid: canadaPrepaidResults, Postpaid: canadaPostpaidResults } });
      console.log("Canada Prepaid Default Plans", canadaPrepaidResults);
      console.log("Canada Postpaid Default Plans", canadaPostpaidResults);
    })
  }

  const findPlanByPlanId = (planid: string, allPlans: DataRow[]) => {
    return allPlans.find(plan => plan.planId.toString() === planid);
  }

  useEffect(() => {
    dispatch(saveRouteDetails("plans") as any);
    dispatch(updateShopObject({ startDate: "" }) as any);
    dispatch(updateShopObject({ endDate: "" }) as any);
    dispatch(updateShopObject({ startDateLocal: null }) as any);
    dispatch(updateShopObject({ endDateLocal: null }) as any);
    dispatch(updateShopObject({ simcard_no: "" }) as any);
    dispatch(updateShopObject({ promocode: "" }) as any);
    dispatch(updateShopObject({ prepaidPromocode: "" }) as any);
    dispatch(updateShopObject({ promocredit: 0 }) as any);
    resetShopState();

    // canada partner id currently does nothing but when its implemented
    // it will do something :)
    let partnerIds = getPartnerIdFromPageLocation(location);

    // update shop with whether or not it's a partner
    if (partnerIds.canadaPartnerId !== 0 || partnerIds.usPartnerId !== 0) {
      if (specialLanguages.includes(language)) {
        dispatch(updateShopObject({ fromSpecialLanguagePartner: true }) as any);
      }
      dispatch(updateShopObject({ fromPartner: true }) as any);
    } else {
      dispatch(updateShopObject({ fromPartner: false }) as any);
      dispatch(updateShopObject({ fromSpecialLanguagePartner: false }) as any);
    }

    // RBC partner id, remove for security
    // save in local storage
    if (partnerIds.canadaPartnerId === 4036) {
      window.history.pushState({}, "Monthly Plans, Prepaid Plans | PhoneBox", "/plans");
      sessionStorage.setItem("raLink", "true");
    }

    verifyURLParamsAndGetCorrespondingPlans(partnerIds);
    // switch country to US if sent by US plans link
    fromUSLinkHandle();
    prepaidLinkHandle();
  }, []);

  useEffect(() => {
    let planListGoogle = [];

    // compile all plan types into one list for event push.
    if (planDetails.Canada.Postpaid.length > 0) {
      planListGoogle = planListGoogle.concat(parseGtagPlans(planDetails.Canada.Postpaid, "Canada Postpaid"));
    }

    if (planDetails.Canada.Prepaid.length > 0) {
      planListGoogle = planListGoogle.concat(parseGtagPlans(planDetails.Canada.Prepaid, "Canada Prepaid"));
    }

    if (planDetails.USA.Prepaid.length > 0) {
      planListGoogle = planListGoogle.concat(parseGtagPlans(planDetails.Canada.Prepaid, "USA Prepaid"));
    }

    // ask trena digital about this if it's incorrect.
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'view_item_list',
      item_list_name: 'plans',
      ecommerce: {
        items: planListGoogle
      }
    });

  }, [planDetails]);

  // these specifications were given to us by Trena digital
  const parseGtagPlans = (plans: Plan[], category: string) => {
    let parsedGtagPlans = plans.map((plan: Plan, index: number) => {
      return {
        item_name: plan.data,
        item_id: plan.planId,
        price: plan.plan_fee,
        item_category: category,
        item_variant: plan.planTypeD,
        item_list_name: category,
        index: index
      }
    });

    return parsedGtagPlans;
  }

  const PlansSection = () => {
    return (
      <div style={{ marginBottom: 100 }}>
        <NewPlanSelectList
          country={country}
          currency={constants.USA_CURRENCY}
          enableESIM={true}
          plans={planDetails}
          enableShipping={true}
          initialPlanType={initialPlanType}
          availablePlanTypes={['prepaid', 'postpaid']}
        />
      </div>
    );
  };

  return (
    <PageLayout>
      <title>Monthly Plans, Prepaid Plans | PhoneBox</title>
      <Container>
        <Section>
          <div
            className={plansStyles.planActions}
            style={{
              background: `url(${country == constants.CANADA
                ? "/images/bg-plan-can.png"
                : "/images/bg-plan-usa.png"
                }) no-repeat`,
            }}
          >
            <PlanSwitchRadio
              onChangeValue={onSwitchCountry}
              country={country}
            />
            <div className={plansStyles.planInfoContainer}>
              <div className={plansStyles.planInfoDescription}>
                <Trans>
                  Shop {country == constants.CANADA ? "Canadian " : "American "}
                  Plans
                </Trans>
              </div>
              <div className={plansStyles.planInfoTitle}>
                <Trans>All plans include unlimited talk & text</Trans>
              </div>
            </div>
          </div>
          <PlansSection />
          <LearnMoreSection country={country} />
        </Section>
      </Container>
      {applicationDown &&
        <PortableModal 
          noCloseButton
          body={
            <div style={{ textAlign: 'center', padding: "0px 25px" }}>
              <div style={{ fontWeight: 700, fontSize: "16px", fontFamily: 'Poppins', color: '#0494CA', margin: "30px 0px 20px 0px" }}>Services Temporarily Unavailable</div>
              <div style={{ fontFamily: 'Poppins' }}>Your services will remain unaffected but no new request or activation is possible at this moment.</div>
              <div style={{ fontWeight: 700, fontFamily: 'Poppins', width: "300px", margin: "20px auto 30px auto" }}>Please refresh the page, or come back very soon!</div>
            </div>
          }
          onClose={onCloseModal}
        ></PortableModal>
      }
      {modalVisible && <Modal 
        onClose={onCloseModal}
        body={<div style={{ padding: 10, marginLeft: 20, marginRight: 20  }}>The referral link you are attempting to use is invalid. Please check your link again or receive one from your friend.</div>}
      />}
    </PageLayout>
  );
};

export default Plans;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Section = styled.div`
  padding: 30px 0px;
`;

export const Head = () => {
  return (
    <>
      <script dangerouslySetInnerHTML={{
        __html: `
          function scriptExists() {
            return document.querySelectorAll("script[src='https://www.googletagmanager.com/gtm.js?id=GTM-TJKD8FQ']").length > 0;
          }

          if(!scriptExists()) {
            console.log("does not exist. injecting...");
            (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-TJKD8FQ');
          }

          var _dcq = _dcq || [];
          var _dcs = _dcs || {};
          _dcs.account = '8316321';
        
          (function() {
            var dc = document.createElement('script');
            dc.type = 'text/javascript'; dc.async = true;
            dc.src = '//tag.getdrip.com/8316321.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(dc, s);
          })();
        `
      }}/>
    </>
  )
}